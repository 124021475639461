import React from "react";

// Customizable Area Start
import { Input, Button, Typography, Checkbox } from '@builder/component-library';

import { View, StyleSheet } from "react-native";
import { imgVisbility, imgVisbilityOff } from "./assets";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <View style={styles.main}>
        <View style={styles.body}>
          <Typography component="h2" variant="4xl" style={styles.textFont}>
            {this.labelTitle}
          </Typography>
          <Typography component="h1" variant="base" style={styles.textFont}>
            {this.state.labelHeader} {/*UI Engine::From Sketch*/}
          </Typography>

          <View style={styles.formWrapper}>
            <View style={styles.inputWrapper}>
              <Input
                data-test-id="txtInputEmail"
                placeholder={"Email"}
                value={this.state.email}
                onChangeText={(text) => this.setEmail(text)}
              />
            </View>
            <View style={styles.inputWrapper}>
              <Input
                data-test-id="txtInputPassword"
                secureTextEntry={this.state.enablePasswordField}
                placeholder={"Password"}
                value={this.state.password}
                onChangeText={(text) => this.setPassword(text)}
                rightIcon={
                  <Button
                    data-test-id="btnTogglePassword"
                    aria-label="toggle password visibility"
                    onPress={this.handleClickShowPassword}
                    style={[styles.removeBackground]}
                    icon={this.state.enablePasswordField ? (
                      <img src={imgVisbility} />
                    ) : (
                      <img src={imgVisbilityOff} />
                    )}
                  />
                }
              />
            </View>
            <View style={{ display: "flex", alignItems: "flex-start" }}>
              <Button
                data-test-id={"btnForgotPassword"}
                style={[styles.removeBackground, styles.buttonsWrapper]}
                textStyle={styles.checkBoxText}
                onPress={() => this.goToForgotPassword()}
                text="Forgot Password ?"
              />
              <Checkbox
                data-test-id={"btnRememberMe"}
                style={{ margin: 5 }}
                textStyle={styles.checkBoxText}
                title="Remember Me"
                onPress={() =>
                  this.setRememberMe(!this.state.checkedRememberMe)
                }
                checked={this.state.checkedRememberMe}

              />
            </View>
            <View
              style={styles.buttonsWrapper}
            >
              <Button
                data-test-id={"btnEmailLogIn"}
                onPress={() => this.doEmailLogIn()}
                text="Login"
              />
            </View>
            <View
              style={styles.OrWrapper}
            >
              <Typography component="h2">
                {this.state.labelOr}
              </Typography>
            </View>
            <View
              style={styles.buttonsWrapper}
            >
              <Button
                data-test-id="btnSocialLogin"
                onPress={() => this.goToSocialLogin()}
                text={this.state.btnTxtSocialLogin}
              />
            </View>
          </View>
        </View>
      </View>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
