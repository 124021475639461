Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Loyalty System";
exports.labelBodyText = "LoyaltySystem Body";

exports.btnExampleTitle = "CLICK ME";
exports.RewardListingHeader = "Rewards";
exports.itemList = "Item List";
exports.gotoReweardList = "Go To Reward List";
exports.itemListingEndPoint = "bx_block_catalogue/catalogues";
exports.buyItemsApiEndPoint = "bx_block_order_management/orders";
exports.rewardListingEndPoint = "bx_block_loyaltysystem/rewards";
exports.buy = "BUY";
exports.Redeem = "REDEEM";
exports.rewardPoints = "Reward Points";
exports.rewardList = "Reward List";
exports.noDataFound = "No data found!";
exports.rewardAskingText = "How many reward points you want to redeem?";
exports.enterPoints = "Enter Points";
exports.redeemApiEndPoint = "bx_block_loyaltysystem/rewards/redeem-points";
exports.FormDataContentType = "multipart/form-data";
exports.okay = "Okay";
exports.cancel = "Cancel";

//for web
exports.getAPiMethod = "GET";
exports.getContentType = "application/json";
exports.itemListApiUrl =
  "bx_block_catalogue/catalogues";
exports.rewardsApiUrl =
  "bx_block_loyaltysystem/rewards";

exports.postAPiMethod = "POST";
exports.postContentType = "application/json";
exports.buyItemApiUrl =
  "bx_block_order_management/orders";

exports.textInputPlaceHolder = "Enter Text";
exports.btnBuyTitle = "buy";
// Customizable Area End