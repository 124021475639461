import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert, Platform } from "react-native";

export interface ItemDataInterface {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name": string,
    "description": string,
    "manufacture_date": string,
    "price": number,
    "loyalty_points": number,
    "catalogue_variants":
    {
      "id": string,
      "type": string,
      "attributes": {
        "id": number,
        "catalogue_id": number,
        "price": number | null,
      }
    }[],
  }
}

export interface FocusSubInterface { remove: () => void; }

//for web
export enum ViewTypes {
  SHOP = 'shop',
  REWARD = 'reward'
}

export interface ItemType {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name": string,
    "price": number,
    "catalogue_variants":
    {
      "id": string,
      "attributes": {
        "id": number,
        "price": number | null,
      }
    }[],
  }
}

export interface RewardType {
  "id": string,
  "type": string,
  "attributes": {
    "points": number,
    "price": number,
    "account_id": string,
    "status": string,
    "created_at": string,
    "title": string
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  itemData: ItemDataInterface[];
  selectedItems: number[];
  loader: boolean;
  //for web
  view: ViewTypes;
  errorMsg: string;
  successMsg: string;
  items: ItemType[];
  totalReward: number;
  rewards: RewardType[];
  token: string;
  open: boolean;
  redeemPoints: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoyaltySystemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitRequestCallId: string = "";
  buyRequestCallId: string = "";
  willFocusSubscription: FocusSubInterface = { remove: () => { } };
  //for web
  buyItemsApiCallId: string = "";
  itemListsApiCallId: string = "";
  rewardsApiCallId: string = "";
  redeemApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      itemData: [],
      selectedItems: [],
      loader: false,
      //for web
      view: ViewTypes.SHOP,
      errorMsg: "",
      successMsg: "",
      items: [],
      totalReward: 0,
      rewards: [],
      token: '',
      open: false,
      redeemPoints: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.getTokenFun(token);
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.apiResponseCallback(message)
    }
  }
  // Customizable Area End

  // Customizable Area Start

  async componentDidMount() {
    this.getToken()
  }

  async componentWillUnmount() {
    this.willFocusSubscription.remove()
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  apiResponseCallback = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson && !responseJson.error && !errorReponse) {
      this.setState({ errorMsg: "" });
      if (apiRequestCallId === this.submitRequestCallId) {
        this.setState({ loader: false, itemData: responseJson.data })
      }
      else if (apiRequestCallId === this.buyRequestCallId) {
        if (responseJson.data) {
          this.setState({ selectedItems: [], loader: false })
          Alert.alert("Alert", "You have bought selected items successfully!");
        }
        else {
          Alert.alert("Alert", "Please try again later!");
          this.setState({ loader: false })
        }
      }
      //for web
      else if (apiRequestCallId === this.itemListsApiCallId) {
        this.setState({ items: responseJson.data, selectedItems: [] });
      }
      else if (apiRequestCallId === this.buyItemsApiCallId) {
        this.setState({ selectedItems: [] })
        alert("You have bought selected items successfully!");
      }
      else if (apiRequestCallId === this.rewardsApiCallId) {
        this.setState({
          rewards: responseJson.rewards?.data,
          totalReward: responseJson?.total_points,
        });
      }
      else if (apiRequestCallId === this.redeemApiCallId) {
        this.handleClose()
        this.getRewards()
        this.setState({ successMsg: "You have redeemed items successfully!", redeemPoints: "" });
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
      }
      else {
        this.setState({ loader: false });
      }
    } else {
      this.setState({ loader: false, errorMsg: "Something Went Wrong." });
      setTimeout(() => {
        this.setState({ errorMsg: "" });
      }, 2000);
    }
  }

  getTokenFun = (token: string) => {
    if (token) {
      this.setState({ token: token }, () => {
        if (Platform.OS !== 'web') {
          this.submitRequestCallId = this.apiCall()
        } else {
          this.getItems();
        }
      });
    }
    else {
      alert("Please Login First!");
    }
  }

  apiCall = () => {
    this.setState({ loader: true });
    let header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.itemListingEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  buyApiCall = () => {
    this.setState({ loader: true });
    let header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    let quantity = new Array(this.state.selectedItems.length).fill(1);

    let amount = 0;
    this.state.itemData.forEach((itemName) => {
      if ((this.state.selectedItems.includes(Number(itemName.attributes?.catalogue_variants[0].id)))) {
        amount += itemName.attributes.price
      }
    })

    const httpBody = {
      quantities: quantity,
      catalogue_variant_ids: this.state.selectedItems,
      amount: amount
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.buyItemsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  onPressReward = () => {
    this.setState({ selectedItems: [] })
    this.props.navigation.navigate("RewardListing");
  }

  goBackFun = () => {
    this.props.navigation.goBack();
  }

  onPressItem = (itemId: number) => {
    if (this.state.selectedItems.includes(itemId)) {
      let newArray = this.state.selectedItems;
      let arrayIndex = newArray.indexOf(itemId);
      newArray.splice(arrayIndex, 1);
      this.setState({ selectedItems: newArray });
    }
    else {
      this.setState({ selectedItems: [...this.state.selectedItems, itemId] });
    }
  }

  onPressBuyBtn = () => {
    this.buyRequestCallId = this.buyApiCall();
  }

  //for web
  setView = (view: ViewTypes) => {
    this.setState({ view: view });
  };

  setShopView = () => {
    this.getItems();
    this.setView(ViewTypes.SHOP);
    this.setState({ selectedItems: [] });
  };

  setRewardView = () => {
    this.getRewards();
    this.setView(ViewTypes.REWARD);
    this.setState({ selectedItems: [] });
  };

  onBuyClick = () => {
    this.buyItems();
  };

  handleClose = () => { this.setState({ redeemPoints: '', open: false }) }

  handleOpen = () => { this.setState({ open: true }) }

  rewardChangeHandler = (reward: string) => {
    this.setState({ redeemPoints: reward })
  }

  handleRewardRedeem = () => {
    if (this.state.redeemPoints.trim() == '' ||
      this.state.redeemPoints.trim() == '0' ||
      !/^\d*$/.test(this.state.redeemPoints.trim())
    ) {
      alert("Please enter valid points!");
    }
    else if (Number(this.state.redeemPoints.trim()) > this.state.totalReward) {
      alert("Redeemed points should not be greater than total reward points!");
    }
    else {
      this.redeemPoints();
    }
  }

  getItems(): boolean {
    const header = {
      "Content-Type": configJSON.getContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.itemListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.itemListApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getRewards(): boolean {
    const header = {
      "Content-Type": configJSON.getContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rewardsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rewardsApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  buyItems(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const totalPrice = this.state.items.reduce(
      (accumulator, item) => {
        let itemPrice = 0
        if (this.state.selectedItems.includes(item?.attributes?.catalogue_variants[0].attributes.id)) {
          itemPrice = item?.attributes.price || 0
        }
        return accumulator + itemPrice
      },
      0
    );

    const attrs = {
      catalogue_variant_ids: this.state.selectedItems,
      quantities: Array(this.state.selectedItems.length).fill(1),
      amount: totalPrice
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.buyItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.buyItemApiUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  redeemPoints = () => {
    this.setState({ loader: true });
    let header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    let formData = {
      "points": parseInt(this.state.redeemPoints)
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.redeemApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.redeemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
